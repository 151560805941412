import { render, staticRenderFns } from "./music-list.vue?vue&type=template&id=6228edae&scoped=true&"
import script from "./music-list.vue?vue&type=script&lang=js&"
export * from "./music-list.vue?vue&type=script&lang=js&"
import style0 from "./music-list.vue?vue&type=style&index=0&id=6228edae&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6228edae",
  null
  
)

export default component.exports